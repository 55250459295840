import CssUtil from "./Css.es6";

class Position
{
    /**
     *
     * @param {HTMLElement|Element} _obj
     * @param {string} _parentClass
     * @returns {number}
     */
    getY(_obj, _parentClass= null)
    {
        let curTop = 0;

        if(_obj.offsetParent)
        {
            do
            {
                curTop += _obj.offsetTop;

                if( _parentClass && CssUtil.hasClassName(_obj.offsetParent, _parentClass)) break;
            }
            while((_obj = _obj.offsetParent));
        }

        return curTop;
    }

    /**
     *
     * @param _obj
     * @returns {number}
     */
    getX(_obj)
    {
        let curLeft = 0;

        if(_obj.offsetParent)
        {
            do
            {
                curLeft += _obj.offsetLeft;
            }
            while((_obj = _obj.offsetParent));
        }

        return curLeft;
    };

    // noinspection JSUnusedGlobalSymbols
    /**
     *
     * @returns {number}
     */
    getScrollX()
    {
        const supportPageOffset = window.pageXOffset !== undefined,
            isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");

        return (supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft);
    };

    /**
     *
     * @param {HTMLElement} _obj
     * @returns {number}
     */
    getScrollY(_obj)
    {
        if(!_obj)
        {
            _obj =  ((document.compatMode || "") === "CSS1Compat") ? document.documentElement : document.body;
        }

        return _obj.scrollTop;
    };
}

const PositionUtil = new Position();

export default PositionUtil;