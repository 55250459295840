class Uri {

    verifyHttpCode(_url, _options) {

        if(!_options.code)
        {
            _options.code = [200];
        }

        if(!Array.isArray(_options.code))
        {
            _options.code = [_options.code];
        }

        const http = new XMLHttpRequest();
        http.open('HEAD', _url);
        http.onreadystatechange = () => {
            if (http.readyState === http.DONE) {
                if (_options.code.indexOf(http.status) >= 0)
                {
                    if(_options.callback)
                    {
                        _options.callback({
                            code: http.status
                        });
                    }
                }
                else
                {
                    if(_options.timeout)
                    {
                        setTimeout(()=> {
                            this.verifyHttpCode(_url, _options);
                        }, _options.timeout);
                    }
                }
            }
        };
        http.send();
    }
}

export default new Uri();