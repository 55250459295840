class Basic
{
    createDomElement(_opt)
    {
        if(_opt["tagName"] !== undefined)
        {
            const element = document.createElement(_opt["tagName"]);
            let attr;
            delete _opt["tagName"];

            if(_opt["innerHTML"] !== undefined)
            {
                element.innerHTML = _opt["innerHTML"];
                delete _opt["innerHTML"];
            }

            if(_opt["children"] !== undefined)
            {
                let i, count;

                for(i = 0, count = _opt["children"].length; i < count; ++i)
                {
                    if( _opt["children"][i] instanceof HTMLElement )
                    {
                        element.appendChild(_opt["children"][i]);
                    }
                    else
                    {
                        element.appendChild(this.createDomElement(_opt["children"][i]));
                    }
                }

                delete _opt["children"];
            }

            for(attr in _opt)
            {
                if(attr === "className")
                {
                    element.setAttribute("class", _opt[attr]);
                    continue;
                }

                if(attr === "ariaLabel")
                {
                    element.setAttribute("aria-label", _opt[attr]);
                    continue;
                }

                if(typeof _opt[attr] === "function")
                {
                    cl.event.addListener(element, attr, _opt[attr]);
                    continue;
                }

                element.setAttribute(attr, _opt[attr]);
            }

            return element;
        }
        else if(_opt["domObj"] !== undefined && _opt["domObj"] instanceof HTMLElement)
        {
            return _opt["domObj"];
        }

        return null;
    }

    deleteDomElement(_obj)
    {
        _obj.parentNode.removeChild(_obj);
    }

    getElementsByClassName(className, parentElement)
    {
        if(parentElement === undefined || parentElement === null)
        {
            parentElement = document;
        }

        if(typeof parentElement.getElementsByClassName === 'function')
        {
            return Array.prototype.slice.call(parentElement.getElementsByClassName(className));
        }

        const elements = parentElement.getElementsByTagName('*'), classElements = [];
        let i;

        for (i = elements.length; i--;)
        {
            if((' ' + elements[i].className + ' ').indexOf(' ' + className + ' ') > -1)
            {
                classElements.unshift(elements[i]);
            }
        }

        return classElements;
    }

    getElementByClassName(className, parentElement)
    {
        const elements = this.getElementsByClassName(className, parentElement);

        return (elements.length === 0 ? null : elements[0]);
    }

    addClassName(obj, className)
    {
        if(!obj || !className) return false;

        const classNameList = Array.isArray(className) ? className : className.split(/[\s\,]/);
        let key;

        if(Array.isArray(obj))
        {
            for (key in obj) {

                this.addClassName(obj[key], classNameList);
            }
        }
        else
        {
            for (key in classNameList) {

                obj.classList.add(classNameList[key]);
            }
        }

        return true;
    }

    removeClassName(obj, className)
    {
        if(!obj) return false;

        let key;

        if(Array.isArray(obj))
        {
            for(key in obj)
            {
                this.removeClassName(obj[key], className);
            }

            return true;
        }
        else if(Array.isArray(className))
        {
            for(key in className)
            {
                this.removeClassName(obj, className[key]);
            }

            return true;
        }
        else
        {
            if(obj && (' ' + obj.className + ' ').indexOf(' ' + className + ' ') > -1)
            {
                obj.className = (' ' + obj.className + ' ').replace(' ' + className + ' ', ' ').replace('  ',' ').replace('  ',' ');

                obj.className = obj.className.replace('  ', ' ').trim();

                return true;
            }
        }

        return false;
    }

    hasClassName(obj, className)
    {
        if(!obj) return false;

        return ((" " + obj.className + " ").replace(/[\n\t]/g, " ").indexOf(" " + className + " ") > -1 );
    }

    changeClassName(obj, className1, className2)
    {
        if(this.hasClassName(obj, className1))
        {
            this.removeClassName(obj, className1);

            if(className2 !== undefined)
            {
                this.addClassName(obj, className2);
            }

            return true;
        }
        else if(this.hasClassName(obj, className2))
        {
            this.removeClassName(obj, className2);
            this.addClassName(obj, className1);

            return true;
        }
        else
        {
            return this.addClassName(obj, className1)
        }
    }

    getFileExtension(_filename)
    {
        return _filename.split('.').pop().toLowerCase();
    }

    indexOfObject(_obj, value)
    {
        let key;

        for(key in _obj)
        {
            if( _obj.hasOwnProperty(key) &&
                _obj[key] === value) return key;
        }

        return undefined;
    }

    syncArray(_array1, _array2)
    {
        const array1 = _array1 || [], array2 = _array2 || [];
        let key;

        for(key in array1)
        {
            if( array2.hasOwnProperty(key) &&
                array1.hasOwnProperty(key))
            {
                array1[key] = array2[key];
            }
        }

        return array1;
    }

    syncObject(_a, _b)
    {
        if(_a === undefined) return _b;

        let key;

        for(key in _b)
        {
            if( _a[key] !== null &&
                _b[key] !== null &&
                typeof _a[key] === 'object' &&
                typeof _b[key] === 'object' &&
                _a[key].tagName === undefined &&
                _b[key].tagName === undefined)
            {
                _a[key] = this.syncObject(_a[key], _b[key]);
            }
            else
            {
                _a[key] = _b[key];
            }
        }

        return _a;
    }

    isObject(_obj)
    {
        return typeof _obj === 'object' && !!_obj;
    }

    isFunction(_func)
    {
        return typeof _func === 'function' && !!_func;
    }

    clone(_src)
    {
        const target = {};
        let prop;

        for (prop in _src)
        {
            if (_src.hasOwnProperty(prop))
            {
                if (this.isObject(_src[prop]))
                {
                    target[prop] = this.clone(_src[prop]);
                }
                else
                {
                    target[prop] = _src[prop];
                }
            }
        }

        return target;
    }

    getYPosition(_obj, _parent)
    {
        let curTop = 0;

        if(_obj.offsetParent)
        {
            do
            {
                curTop += _obj.offsetTop;

                if( _parent && _obj.offsetParent === _parent) break;
            }
            while((_obj = _obj.offsetParent));
        }

        return curTop;
    }

    getXPosition(_obj)
    {
        let curLeft = 0;

        if(_obj.offsetParent)
        {
            do
            {
                curLeft += _obj.offsetLeft;
            }
            while((_obj = _obj.offsetParent));
        }

        return curLeft;
    }

    getXScroll()
    {
        const supportPageOffset = window.pageXOffset !== undefined,
            isCSS1Compat = ((document.compatMode || "") === "CSS1Compat");

        return (supportPageOffset ? window.pageXOffset : isCSS1Compat ? document.documentElement.scrollLeft : document.body.scrollLeft);
    }

    getYScroll(_obj)
    {
        if(!_obj)
        {
            _obj =  ((document.compatMode || "") === "CSS1Compat") ? document.documentElement : document.body;
        }

        return _obj.scrollTop;
    }

    parseStr(_str)
    {
        const tmp = _str.split("&"), pairArray = [];
        let pair, i;

        for(i = 0; i < tmp.length; ++i)
        {
            pair = tmp[i].split("=");

            pairArray[pair[0]] = pair[1];
        }

        return pairArray;
    }

    buildUriParamsByObject(_obj)
    {
        let proc, pairs = [];

        (proc = function(object, prefix)
        {
            let el, i, key, value, _results;

            if (object === null) object = _obj;
            if (prefix === null) prefix = null;

            _results = [];

            for (key in object)
            {
                if (!object.hasOwnProperty(key)) continue;

                value = object[key];

                if (value instanceof Array)
                {
                    _results.push((function()
                    {
                        var _len, _results2;
                        _results2 = [];

                        for (i = 0, _len = value.length; i < _len; i++)
                        {
                            el = value[i];
                            _results2.push(proc(el, prefix !== null ? "" + prefix + "[" + key + "][]" : "" + key + "[]"));
                        }

                        return _results2;
                    })());
                }
                else if (value instanceof Object)
                {
                    if (prefix !== null)
                    {
                        prefix += "[" + key + "]";
                    }
                    else
                    {
                        prefix = key;
                    }

                    _results.push(proc(value, prefix));
                }
                else
                {
                    _results.push(pairs.push(prefix !== null ? "" + prefix + "[" + key + "]=" + encodeURIComponent(value) : "" + key + "=" + encodeURIComponent(value)));
                }
            }

            return _results;
        })();

        return pairs.join('&').replace(/%20/g, '+');
    }

    isFullscreen()
    {
        if(document.fullScreenElement !== undefined)
        {
            return document.fullScreenElement !== null;
        }
        else if(document.webkitFullscreenElement !== undefined)
        {
            return document.webkitFullscreenElement !== null;
        }
        else if(document.mozFullScreenElement !== undefined)
        {
            return document.mozFullScreenElement !== null;
        }
        else if(document.msFullscreenElement !== undefined)
        {
            return document.msFullscreenElement !== null;
        }

        return false;
    }

    scrollToElement(_obj, _parent)
    {
        if(!_obj) return;

        let speed;

        if(!_parent)
        {
            _parent = _obj;

            do
            {
                _parent = _parent.parentNode;

                if (!(_parent instanceof Element))
                {
                    _parent = null;

                    break;
                }

                if(window.getComputedStyle(_parent)['overflow-y'] === 'scroll' || window.getComputedStyle(_parent)['overflow-y'] === 'auto') break;
            }
            while(_parent);

            if(!_parent)
            {
                _parent =  ((document.compatMode || "") === "CSS1Compat") ? document.documentElement : document.body;
            }
        }

        let startY = cl.basic.getYScroll(_parent),
            stopY = cl.basic.getYPosition(_obj, _parent),
            distance;

        if(stopY === 0) return;

        stopY -= 100;

        distance = stopY > startY ? stopY - startY : startY - stopY;

        if (distance < 80)
        {
            _parent.scrollTo(0, stopY); return;
        }

        speed = Math.round(distance / 80);

        if (speed >= 20)
        {
            speed = 20;
        }

        const step = Math.round(distance / 25);

        let leapY = stopY > startY ? startY + step : startY - step, timer = 0, i;

        var scrollHelper = function(_leapY) {

            return function(){
                _parent.scroll(0, _leapY);
            };
        };

        if (stopY > startY)
        {
            for (i=startY; i<stopY; i+=step )
            {
                setTimeout(scrollHelper(leapY), timer * speed);
                leapY += step;
                if (leapY > stopY)
                {
                    leapY = stopY;
                }

                timer++;
            }

            return;
        }

        for (i=startY; i>stopY; i-=step )
        {
            setTimeout(scrollHelper(leapY), timer * speed);
            leapY -= step; if (leapY < stopY) {leapY = stopY;}
            timer++;
        }
    }

    stripTags(_string)
    {
        return _string.replace(/<\/?[^>]+(>|$)/g, "");
    }

    getElementIndex(element)
    {
        return [].indexOf.call(element.parentNode.children, element);
    }

    getDomIdentifier(_obj)
    {
        let id = '';

        while(_obj.parentNode !== null)
        {
            id += _obj.tagName + this.getElementIndex(_obj);

            _obj = _obj.parentNode;
        }

        return cl.util.md5(location.href + id);
    }

    isInViewport(el)
    {
        const rect = el.getBoundingClientRect();

        return (
            /*
            for vertical checking
            rect.right > 0 &&
            rect.left < (window.innerWidth || document.documentElement.clientWidth) &&
             */
            rect.bottom > 0 &&
            rect.top < (window.innerHeight || document.documentElement.clientHeight)
        )
    }

    base64toBlob(base64Data, contentType)
    {
        contentType = contentType || 'application/octetstream';
        const sliceSize = 1024;
        const byteCharacters = atob(base64Data);
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        let byteArrays = new Array(slicesCount);

        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex)
        {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);

            let bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }

        return new Blob(byteArrays, { type: contentType });
    }
}

const basic = new Basic();

export default basic;