import {EventUtil} from "../../../../../../CrayssnLabs/Base/_resources/js/lib/Dom/Util.es6";

export default class Project {
    constructor() {
        const mainMenuBtn = document.querySelector('.main-menu .burger-menu.swap-toggle > .nav > .btn');
        const formInput = document.querySelectorAll('.form-shift-label .form-element.text .native-element, .form-shift-label .form-element.textarea .native-element');

        let overlays = document.getElementsByClassName('ui-overlay');

        if(mainMenuBtn) {
            EventUtil.addEvent(mainMenuBtn, 'click', () => {
                document.body.classList.toggle('menu-active');
            });
        }

        if(formInput) {
            const focusClass = 'focus';
            for (const formInputElement of formInput) {
                formInputElement.addEventListener('focusin', (event) => {
                    event.target.closest('.element-wrapper').classList.add(focusClass);
                }, true);
                formInputElement.addEventListener('focusout', (event) => {
                    event.target.closest('.element-wrapper').classList.remove(focusClass);
                }, true);
            }
        }

        for (let overlay of overlays) {
            let closeHandler = overlay.querySelector('.close-handler');
            closeHandler.addEventListener('click', () => {
                overlay.classList.add('collapse');
            });
        }
    }
}

new Project();