import PositionUtil from "./Position.es6";
import EventUtil from "../Event/Util.es6";


class Dom {

    /**
     *
     * @param _id
     * @returns {HTMLElement}
     */
    byId(_id)
    {
        return document.getElementById(_id);
    }

    /**
     *
     * @param {string} _className
     * @param {HTMLElement|Element|Document} _parentObj
     * @returns {*[]}
     */
    byClassName(_className, _parentObj = document)
    {
        return [].slice.call(_parentObj.getElementsByClassName(_className));
    }

    /**
     *
     * @param {object} _opt
     * @returns {Element|null}
     */
    createDomElement(_opt)
    {
        if(_opt["tagName"] !== undefined)
        {
            const element = document.createElement(_opt["tagName"]);
            let attr, innerAttr;

            if(_opt["innerHTML"] !== undefined)
            {
                element.innerHTML = _opt["innerHTML"];
                delete _opt["innerHTML"];
            }

            if(_opt["children"] !== undefined)
            {
                let i, count;

                for(i = 0, count = _opt["children"].length; i < count; ++i)
                {
                    if( _opt["children"][i] instanceof HTMLElement )
                    {
                        element.appendChild(_opt["children"][i]);
                    }
                    else
                    {
                        element.appendChild(this.createDomElement(_opt["children"][i]));
                    }
                }

                delete _opt["children"];
            }

            for(attr in _opt)
            {
                if(attr === 'tagName')
                {
                    continue;
                }

                if(typeof _opt[attr] === 'function')
                {
                    EventUtil.addEvent(element, attr, _opt[attr]);
                    continue;
                }
                else if(_opt[attr] === true)
                {
                    element.setAttribute(attr, attr);
                    continue;
                }
                else if(typeof _opt[attr] === 'object')
                {
                    for(innerAttr in _opt[attr])
                    {
                        if(_opt[attr][innerAttr])
                        {
                            element.setAttribute(attr + '-' + innerAttr, _opt[attr][innerAttr]);
                        }
                    }

                    continue;
                }

                element.setAttribute(attr === "className" ? "class" : attr, _opt[attr]);
            }

            return element;
        }
        else if(_opt["domObj"] !== undefined && _opt["domObj"] instanceof HTMLElement)
        {
            return _opt["domObj"];
        }

        if(!_opt["tagName"])
        {
            console.log("Missing 'tagName' property", _opt);
        }

        return null;
    };

    /**
     *
     * @param {Element} _obj
     */
    deleteDomElement(_obj)
    {
        _obj.parentNode.removeChild(_obj);
    };

    /**
     *
     * @param _obj
     * @param _parent
     */
    scrollToElement(_obj, _parent)
    {
        if(!_obj) return;

        let speed;

        if(!_parent)
        {
            _parent =  ((document.compatMode || "") === "CSS1Compat") ? document.documentElement : document.body;
        }

        let startY = PositionUtil.getScrollY(_parent),
            stopY = PositionUtil.getY(_obj),
            distance;

        if(stopY === 0) return;

        stopY -= 100;

        distance = stopY > startY ? stopY - startY : startY - stopY;

        if (distance < 80)
        {
            _parent.scrollTo(0, stopY); return;
        }

        speed = Math.round(distance / 80);

        if (speed >= 20)
        {
            speed = 20;
        }

        const step = Math.round(distance / 25);

        let leapY = stopY > startY ? startY + step : startY - step, timer = 0, i;

        const scrollHelper = function(_leapY) {

            return function(){
                _parent.scroll(0, _leapY);
            };
        };

        if (stopY > startY)
        {
            for (i=startY; i<stopY; i+=step )
            {
                setTimeout(scrollHelper(leapY), timer * speed);
                leapY += step;
                if (leapY > stopY)
                {
                    leapY = stopY;
                }

                timer++;
            }

            return;
        }

        for (i=startY; i>stopY; i-=step )
        {
            setTimeout(scrollHelper(leapY), timer * speed);
            leapY -= step; if (leapY < stopY) {leapY = stopY;}
            timer++;
        }
    };
}

const DomUtil = new Dom();

export default DomUtil;