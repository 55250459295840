import Store from "../Store.es6";
import {DomUtil, UriUtil} from "./Util.es6";

export default class Loader {

    breakPoints = [];

    constructor() {
        this.testWebPSupport().then(() => {
            this.analyseBreakPoints();
        });
    }

    async testWebPSupport() {

        if (Store.exists('webPSupport')) {
            return;
        }

        return new Promise((resolve, reject) => {
            let img = new Image()
            img.onload = () => resolve(img.height)
            img.onerror = reject


            const webP = new Image();
            webP.onload = webP.onerror = () => {
                Store.set({
                    webPSupport: webP.height === 2
                });

                if (!Store.get().webPSupport) {

                    document.body.classList.add('no-webp');
                }

                resolve();
            };

            webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
        })
    }

    analyseBreakPoints() {

        const breakPoint = DomUtil.createDomElement({
            tagName: 'div'
        });

        document.body.appendChild(breakPoint);

        const parts = ['sm', 'md', 'lg', 'xl', 'xxl'];

        for (let key in parts) {
            breakPoint.id = 'breakpoint-' + parts[key];
            this.breakPoints[parts[key]] = breakPoint.offsetWidth;
        }

        breakPoint.parentNode.removeChild(breakPoint);
    }

    loadVisibleElements(_domElement)
    {
        if(_domElement === undefined)
        {
            _domElement = ((document.compatMode || "") === "CSS1Compat") ? document.documentElement : document.body;
        }

        const elementsToLoad = _domElement.querySelectorAll('[data-src]:not(.element-loaded):not(.element-not-loaded):not(.element-loading)');
        let element;

        for(let i = 0; i < elementsToLoad.length; ++i)
        {
            element = elementsToLoad[i];

            if(!cl.basic.isInViewport(element))
            {
                continue;
            }

            if(element.tagName === 'IMG')
            {
                this.loadVisibleImage(element)
            }
            else if(element.tagName === 'VIDEO')
            {
                this.loadVisibleVideo(element)
            }
        }
    }

    loadVisibleImage(element)
    {
        if (!Store.exists('webPSupport')) {
            return;
        }

        const ratio = window.devicePixelRatio || 1;

        let elementUrl = element.dataset.src;
        let width = element.parentNode.offsetWidth * ratio;
        let height = element.parentNode.offsetHeight * ratio;

        if(elementUrl.indexOf('||') > -1 || elementUrl.indexOf('::') > -1)
        {
            let urls = elementUrl.split('||');
            let a, tmp, urlByBreakPoint = [];

            elementUrl = null;

            for(a = 0; a < urls.length; ++a)
            {
                tmp = urls[a].split('::');

                if(this.breakPoints[tmp[0]] === undefined)
                {
                    elementUrl = tmp[1];

                    continue;
                }

                urlByBreakPoint.push({url: tmp[1], breakPoint: this.breakPoints[tmp[0]]});
            }

            urlByBreakPoint.sort(function (a, b) {
                return a.breakPoint > b.breakPoint ? 1 : -1;
            });

            for(a = 0; a < urlByBreakPoint.length; ++a)
            {
                elementUrl = urlByBreakPoint[a].url;

                if(urlByBreakPoint[a].breakPoint > window.innerWidth)
                {
                    break;
                }
            }
        }

        if(elementUrl !== null)
        {
            const ratioWidth = element.dataset.width * ratio;
            const ratioHeight = element.dataset.height * ratio;

            if(elementUrl.indexOf('cut') > -1)
            {
                if(element.dataset.width && element.dataset.height)
                {
                    element.style.height = element.dataset.height + 'px';
                    element.style.width = element.dataset.width + 'px';

                    elementUrl = elementUrl.replace(/(.*)([max|cut])_(\d*)_(\d*)(.*\/.*)/g, "$1$2_" + ratioWidth + "_" + ratioHeight + "$5");
                }
                else
                {
                    elementUrl = elementUrl.split(/(.*[max|cut])_(\d*)_(\d*)(.*)(\/)(.*)/g);

                    if(parseInt(elementUrl[2]) === 0 && parseInt(elementUrl[3]) === 0)
                    {
                        elementUrl[3] = '_' + height;
                        elementUrl[2] = '_' + width;
                    }
                    else
                    {
                        elementUrl[3] = '_' + Math.round(elementUrl[3] * (width / elementUrl[2]));
                        elementUrl[2] = '_' + width;
                    }

                    elementUrl = elementUrl.join("");
                }
            }
            else
            {
                if(element.dataset.height)
                {
                    element.style.height = element.getAttribute('data-height') + 'px';
                    elementUrl = elementUrl.replace(/(.*)([max|cut])_(\d*)_(\d*)(.*\/.*)/g, "$1$2_0_" + ratioHeight + "$5");
                }
                else if(element.dataset.width)
                {
                    element.style.width = element.getAttribute('data-width') + 'px';
                    elementUrl = elementUrl.replace(/(.*)([max|cut])_(\d*)_(\d*)(.*\/.*)/g, "$1$2_" + ratioWidth + "_0$5");
                }
                else
                {
                    elementUrl = elementUrl.replace(/(.*)([max|cut])_(\d*)_(\d*)(.*\/.*)/g, "$1$2_" + width + "_0$5");
                }
            }

            if(Store.get().webPSupport)
            {
                if(elementUrl.indexOf('.webp') === -1)
                {
                    elementUrl = elementUrl.replace('.png', '.webp').replace('.jpg', '.webp');
                }
            }
            else
            {
                if(elementUrl.indexOf('.webp') !== -1)
                {
                    elementUrl = elementUrl.replace('.webp', '.png');
                }
            }

            const elementCache = document.createElement('img');
            elementCache.src = elementUrl;

            if(elementCache.complete || elementCache.width + elementCache.height > 0)
            {
                element.src = elementUrl;
                element.dispatchEvent(new Event('load'));
                element.classList.add('element-cached', 'element-loaded')
            }
            else
            {
                element.classList.add('element-loading');

                if(element.src.indexOf('data:') >= 0)
                {
                    element.addEventListener('load', () => {
                        element.classList.remove('element-loading');
                        element.classList.add('element-loaded');
                    });

                    element.addEventListener('error', () => {
                        if(element.src.indexOf('reload=2') > -1) {
                            element.classList.remove('element-loading','element-loaded');
                            element.classList.add('element-not-loaded');
                        }
                        else {
                            let src = element.src, reloadCount = 1;

                            setTimeout(() => {
                                if(src.indexOf('reload=1') > -1) {
                                    reloadCount++;
                                }

                                src = src.replace(/[&\?]reload=\d/g, '');
                                element.src = src + (src.indexOf('?') === -1 ? '?' : '&') + 'reload=' + reloadCount;
                            }, 2000);
                        }
                    });

                    element.src = elementUrl;
                }
                else
                {
                    element.addEventListener('load', () => {
                        element.addEventListener('load', () => {
                            element.classList.remove('element-loading');
                            element.classList.add('element-loaded');
                        });

                        element.src = element.src.replace(/(.*)([max|cut])_(\d*)_(\d*)(.*)/g, '$1$2_' + element.parentNode.offsetWidth + '_0$5');
                    });
                }
            }
        }
        else
        {
            element.classList.remove('element-loaded');
        }
    }
    loadVisibleVideo(element)
    {
        const sizes = [640, 1280, 1680, 1920, 2560, 4096];

        let lastErrorCode = 0;
        let elementUrl = element.dataset.src;
        let width = element.parentNode.offsetWidth * (window.devicePixelRatio || 1);

        for(let i = 0; i < sizes.length; ++i)
        {
            if(sizes[i] >= width)
            {
                width = sizes[i];
                break;
            }
        }

        if(width > sizes[sizes.length - 1])
        {
            width = sizes[sizes.length - 1];
        }

        elementUrl = elementUrl.replace(/(.*)([max|cut])_(\d+)(.*)/g, '$1$2_' + width + '$4');
        element.src = elementUrl;
        element.poster ||= elementUrl.replace('.mp4', '.jpg');

        const videoNotification = DomUtil.createDomElement({
            'tagName' : 'div',
            'class' : 'video-notification alert alert-info',
            'innerHTML' : 'Das Video wird noch vorbereitet.'
        });

        if(element.videoWidth + element.videoHeight > 0)
        {
            element.classList.add('element-cached', 'element-loaded')
        }
        else
        {
            element.classList.add('element-loading');

            element.addEventListener('canplay', () => {
                element.classList.remove('element-loading', 'element-processing');
                element.classList.add('element-loaded');

                const videoNotification = element.parentNode.querySelector('.video-notification');
                if(videoNotification) {
                    videoNotification.remove();
                }
            });

            const errorCallback = (_event) => {
                if(!element.classList.contains('element-processing'))
                {
                    element.classList.add('element-processing');
                    element.after(videoNotification);
                }

                UriUtil.verifyHttpCode(elementUrl, {
                    code: [200, 201, 202, 206],
                    timeout: 5000,
                    callback: (_options) => {

                        if(_options.code < 300 && lastErrorCode === 0)
                        {
                            return ;
                        }

                        lastErrorCode = _options.code;
                        element.src = elementUrl;

                        cl.event.removeListener(element, 'error', errorCallback);
                    }
                });

                element.controls = false;
            };

            element.addEventListener('error', errorCallback);
        }
    }
}