import {DomUtil} from "./Dom/Util.es6";

export default class Base {

    /**
     *
     * @type {{}}
     */
    config = {};

    /**
     *
     * @type {Updater}
     */
    updater = null;

    constructor(_config) {

        this.prepare(_config);
    }

    prepare(_config) {

        if (_config) {
            for (let key in _config) {
                this.config[key] = _config[key];
            }
        }

        this.ready();
    }

    ready()
    {
        /* lazy loading interval */
        setInterval(() => {
            cl.loader.loadVisibleElements();
        }, 200);

        cl.loader.loadVisibleElements();
        cl.updater.updateDomElements();

        if(window.location.hash)
        {
            const scrollToElement = DomUtil.byId(window.location.hash.replace('#', ''));

            if(scrollToElement)
            {
                DomUtil.scrollToElement(scrollToElement);
            }

            const url = new URL(window.location);
            url.hash = '';

            window.history.pushState({}, '', url);
        }
    }
}