
class Store {

    constructor() {

        if(window.cl === undefined)
        {
            window.cl = {};
        }

        window.cl.store = {};
    }

    set(_data)
    {
        for(let key in _data)
        {
            window.cl.store[key] = _data[key];
        }
    }

    get()
    {
        return window.cl.store;
    }

    exists(_index)
    {
        if(!Object.hasOwn)
        {
            return window.cl.store.hasOwnProperty(_index);
        }

        return Object.hasOwn(window.cl.store, _index);
    }
}

export default new Store();