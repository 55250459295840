export default class Notification {

    notificationObj = null;

    constructor() {
        this.prepare();
    }

    prepare()
    {
        if(cl.byId('notification'))
        {
            this.notificationObj = cl.basic.createDomElement({
                'tagName' : 'div',
                'class' : 'notifications'
            });

            cl.byId('notification').appendChild(this.notificationObj);

            this.addListenerToClick(function(){
                cl.basic.removeClassName(document.body, 'show-notification');
            });

            return true;
        }

        return false;
    }

    append(_notification)
    {
        if(typeof _notification === 'string')
        {
            this.notificationObj.appendChild(
                cl.basic.createDomElement({
                    'tagName' : 'div',
                    'class' : 'notification',
                    'innerHTML' : _notification
                })
            );
        }
        else if(typeof _notification === 'object')
        {
            if(_notification.meta === undefined)
            {
                _notification.meta = {};
            }

            const element = { 'tagName' : 'div',
                'class' : 'notification set-' + _notification.type,
                'innerHTML' : _notification.message};

            if(_notification.meta.reference === undefined)
            {
                _notification.meta.reference = null;
            }

            if(_notification.meta && _notification.meta.reference)
            {
                element['data-reference'] = _notification.meta.reference;
            }

            this.notificationObj.appendChild(cl.basic.createDomElement(element));
        }

        cl.basic.addClassName(document.body, 'show-notification');
    }

    addListenerToClick(_callback)
    {
        cl.event.addListener(cl.byId('notification'), 'click', function(e){

            if(e.target === this)
            {
                _callback(e);
            }
        });
    }

    show(_notification, _clearBeforeAppend)
    {
        if(this.notificationObj === null)
        {
            if(!this.prepare())
            {
                return ;
            }
        }

        if(_clearBeforeAppend)
        {
            this.notificationObj.innerHTML = '';
        }

        if(Array.isArray(_notification))
        {
            let i;

            for(i = 0; i < _notification.length; ++i)
            {
                this.append(_notification[i]);
            }
        }
        else
        {
            this.append(_notification);
        }
    }
}