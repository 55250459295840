import Loader from "./Loader.es6";

export default class Handler
{
    processResponse({additions, notifications, dom}) {

        if(additions)
        {
            let addition, i;

            if(!Array.isArray(additions))
            {
                additions = [additions];
            }

            for(i = 0; i < additions.length; ++i)
            {
                addition = additions[i];

                switch(addition.type)
                {
                    case 'redirect':

                        cl.navigation.redirect(addition.url, addition.target);

                        break;

                    case 'lightbox':

                        cl.lightbox.show(addition);

                        break;

                    case 'download':

                        const a = document.createElement('a');
                        const url = window.URL.createObjectURL(cl.basic.base64toBlob(addition.file.base64));
                        a.href = url;
                        a.download = addition.file.name;
                        document.body.append(a);
                        a.click();
                        a.remove();
                        window.URL.revokeObjectURL(url);

                        break;

                    case 'dialog':

                        addition.option = {...{
                                closable: true,
                                className: 'default-dialog',
                                dialogId: 'default-dialog',
                            },...addition.option}

                        let dialog = document.getElementById(addition.option.dialogId);

                        if(!dialog)
                        {
                            dialog = cl.basic.createDomElement({
                                tagName: 'dialog',
                                id: addition.option.dialogId,
                                className: addition.option.className,
                            });

                            document.body.appendChild(dialog);
                        }

                        dialog.innerHTML = addition.html;

                        if(addition.option.closable)
                        {
                            dialog.addEventListener('click', function(event) {
                                const rect = dialog.getBoundingClientRect();
                                const isInDialog = (rect.top <= event.clientY && event.clientY <= rect.top + rect.height &&
                                    rect.left <= event.clientX && event.clientX <= rect.left + rect.width);
                                if (!isInDialog) {
                                    dialog.close();
                                }
                            });
                        }

                        if(dialog.innerHTML)
                        {
                            dialog.showModal();
                        }
                        else
                        {
                            dialog.close();
                        }

                    break;

                    case 'replacement':

                        const obj = cl.byId(addition.option.id);

                        if(addition.option.dataset)
                        {
                            for (let key in addition.option.dataset)
                            {
                                if(addition.option.dataset[key] === null && obj.dataset[key])
                                {
                                    delete obj.dataset[key];

                                    continue;
                                }

                                obj.dataset[key] = addition.option.dataset[key];
                            }
                        }

                        obj.innerHTML = addition.html;

                        break;
                }

                cl.updater.updateDomElements();
            }
        }

        if(dom)
        {
            this.updateDom(dom);
        }

        if(notifications)
        {
            cl.notification.show(notifications, true);
        }
    };

    updateDom(domElements)
    {
        let tmp, i, ii, elements, key, includedFiles = [];

        for(key in domElements)
        {
            elements = domElements[key];

            if(elements.length === 0) continue;

            switch(key)
            {
                case 'script':

                    const scriptSrc = [];
                    let scriptToEval = '', scriptsToLoad = [];

                    tmp = document.getElementsByTagName('script');

                    for(i = 0; i < tmp.length; ++i)
                    {
                        if(tmp[i].src)
                        {
                            scriptSrc.push(tmp[i].src.replace(/\?.*$/, ''));

                            if(tmp[i].getAttribute('data-included-files'))
                            {
                                includedFiles = tmp[i].getAttribute('data-included-files').split('|');

                                for(ii = 0; ii < includedFiles.length; ++ii)
                                {
                                    scriptSrc.push(includedFiles[ii].replace(/\?.*$/, ''));
                                }
                            }
                        }
                    }

                    for(i = 0; i < elements.length; ++i)
                    {
                        if(elements[i].src && scriptSrc.indexOf(elements[i].src.replace(/\?.*$/, '')) === -1)
                        {
                            scriptsToLoad.push(elements[i].src);
                        }
                        else if(elements[i].content)
                        {
                            scriptToEval += elements[i].content + "\n";
                        }
                    }

                    (new Loader).reloadScript({
                        elements: scriptsToLoad,
                        callback: function() {
                            try
                            {
                                eval(scriptToEval);
                            }
                            catch (e) {
                                console.log(e);
                            }
                        }
                    });

                    break;

                case 'css':

                    tmp = document.getElementsByTagName('link');

                    let cssFiles = [];

                    for(i = 0; i < tmp.length; ++i)
                    {
                        if(tmp[i].href && tmp[i].type === 'text/css')
                        {
                            cssFiles.push(tmp[i].href.replace(/\?.*$/, ''));

                            if(tmp[i].getAttribute('data-included-files'))
                            {
                                includedFiles = tmp[i].getAttribute('data-included-files').split('|');

                                for(ii = 0; ii < includedFiles.length; ++ii)
                                {
                                    cssFiles.push(includedFiles[ii].replace(/\?.*$/, ''));
                                }
                            }
                        }
                    }

                    for(i = 0; i < elements.length; ++i)
                    {
                        if(cssFiles.indexOf(elements[i].href.replace(/\?.*$/, '')) === -1)
                        {
                            document.head.appendChild(cl.basic.createDomElement({
                                tagName: 'link',
                                href: elements[i].href,
                                type: 'text/css',
                                rel: 'stylesheet'
                            }));
                        }
                    }

                    break;
            }
        }
    };

}