import EventUtil from "./Util.es6";
import {DomUtil, CssUtil, PositionUtil} from "../Util.es6";
import EventDispatcher from "./Dispatcher.es6";

export default class Updater
{
    /**
     *
     */
    updateDomElements()
    {
        let i, toPrepare = [], elements = Array.from(document.querySelectorAll('[data-async-type]'));

        if(elements.length > 0)
        {
            for (i = 0; i < elements.length; ++i)
            {
                if(elements[i].getAttribute('data-click-prepared')) continue;

                toPrepare.push(elements[i]);

                elements[i].setAttribute('data-click-prepared', 'true');
            }

            EventUtil.addEvent(toPrepare, 'click', function(event) {

                if(this.dataset.clicked)
                {
                    return;
                }

                this.dataset.clicked = 'true';

                const data = {data: {}};
                let attribute, name;

                for(i = 0; i < this.attributes.length;++i)
                {
                    attribute = this.attributes[i];

                    if(attribute.name.indexOf('data-async-') >= 0)
                    {
                        name = attribute.name.replace('data-async-', '');

                        if(name === 'url')
                        {
                            data[name] = attribute.value;
                        }
                        else
                        {
                            data.data[name] = attribute.value;
                        }
                    }
                }

                data.callback = () => {
                    setTimeout(() => {
                        delete this.dataset.clicked;
                    }, 100);
                };

                EventDispatcher.click(this, data);

                EventUtil.stopEvent(event)
            });
        }

        elements = document.querySelectorAll('[data-scroll-point]');

        const scroll = function(_points)
        {
            return function(e)
            {
                let i;
                const top  = e.target.scrollTop || e.target.documentElement.scrollTop;

                for(i = 0; i < _points.length; ++i)
                {
                    if(top > PositionUtil.getY(_points[i]))
                    {
                        CssUtil.addClassName(document.body, _points[i].getAttribute('data-scroll-point'));
                    }
                    else
                    {
                        CssUtil.removeClassName(document.body, _points[i].getAttribute('data-scroll-point'));
                    }
                }

                if(top > 0)
                {
                    /*cl.basic.addClassName(document.body, 'set-scrolled');*/
                    document.body.classList.add('set-scrolled');
                    document.body.classList.add('page-scrolled');
                }
                else
                {
                    /*cl.basic.removeClassName(document.body, 'set-scrolled');*/
                    document.body.classList.remove('set-scrolled');
                    document.body.classList.remove('page-scrolled');
                }
            }
        };

        EventUtil.addEvent(document, 'scroll', scroll(elements), {passive: true});

        toPrepare = [];
        elements = document.querySelectorAll('[href]');

        if(elements.length > 0)
        {
            for (i = 0; i < elements.length; ++i)
            {
                if(elements[i].getAttribute('data-smooth-scroll-prepared') || elements[i].getAttribute('href').indexOf('#') < 0) continue;

                toPrepare.push(elements[i]);

                elements[i].setAttribute('data-smooth-scroll-prepared', 'true');
            }

            EventUtil.addEvent(toPrepare, 'click', function(e){

                if(DomUtil.byId(this.href.split('#')[1]) !== null)
                {
                    DomUtil.scrollToElement(DomUtil.byId(this.href.split('#')[1]));
                    EventUtil.stopEvent(e);
                }
            });
        }

        EventUtil.addEvent(document, 'scroll', scroll(elements), {passive: true});

        toPrepare = [];
        elements = document.querySelectorAll('[data-clickable]');

        if(elements.length > 0)
        {
            for (i = 0; i < elements.length; ++i)
            {
                if(elements[i].getAttribute('data-clickable-prepared')) continue;

                toPrepare.push(elements[i]);

                elements[i].setAttribute('data-clickable-prepared', 'true');
            }

            EventUtil.addEvent(toPrepare, 'click', function() {

                const obj = DomUtil.byId(this.getAttribute('data-clickable'));
                const options = this.getAttribute('data-clickable-value') ? this.getAttribute('data-clickable-value').split(',') : ['active'];
                const type = this.getAttribute('data-clickable-type');

                if(options.length === 1)
                {
                    options.push('');
                }

                if(type === 'radio' && !obj.classList.contains(options[0]))
                {
                    const radioElements = DomUtil.byClassName(options[0]);

                    if(radioElements.length > 0)
                    {
                        CssUtil.removeClassName(radioElements, options[0]);
                    }
                }

                CssUtil.changeClassName(obj, options[0], options[1]);
            });
        }

        if (location.hash) {
            setTimeout(function() {

                window.scrollTo(0, 0);

                DomUtil.scrollToElement(DomUtil.byId(location.hash.split('#')[1]));
            }, 1);
        }
    };
}