
import Handler from "./Ajax/Handler.es6";
import {DomUtil, EventUtil} from "./Dom/Util.es6";

export default class Ajax
{
    multiRequests = true;
    multiRequestAborts = false;
    additionalHeaders = {};

    /**
     *
     * @type XMLHttpRequest
     */
    activeXMLHttpRequest = null;

    /**
     *
     * @param allowMultiRequests
     * @param allowMultiRequestAborts
     * @param additionalHeaders
     */
    constructor({allowMultiRequests = true, allowMultiRequestAborts = false, additionalHeaders = {}} = {allowMultiRequests: true, allowMultiRequestAborts: false, additionalHeaders: {}}) {

        this.multiRequests = allowMultiRequests;
        this.multiRequestAborts = allowMultiRequestAborts;
        this.additionalHeaders = additionalHeaders;

        if(cl.cookie.get('remote-session'))
        {
            this.additionalHeaders['X-Remote-Session'] = cl.cookie.get('remote-session');
        }
    }

    serialize(obj, prefix) {
        const str = [];
        for (let p in obj)
        {
            const k = prefix ? prefix + "[" + p + "]" : p, v = obj[p];
            str.push((v !== null && typeof v === "object") ?
                this.serialize(v, k) :
                encodeURIComponent(k) + "=" + encodeURIComponent(v));
        }
        return str.join("&").replace(/%20/g, '+');
    }

    sendData({data = {}, callback = null, progress = null, url = null, method = 'POST'})
    {
        const options = {
            data, callback, progress, url, method
        };

        const XMLHttpRequestObj = new XMLHttpRequest();

        if(!this.multiRequests)
        {
            if(this.activeXMLHttpRequest !== null)
            {
                if(this.multiRequestAborts)
                {
                    this.activeXMLHttpRequest.abort();
                }
                else
                {
                    return false;
                }
            }

            this.activeXMLHttpRequest = XMLHttpRequestObj;
        }

        XMLHttpRequestObj.open(method, url || location.href, true);
        XMLHttpRequestObj.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded; Charset=utf-8');
        XMLHttpRequestObj.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

        if(document.body.dataset.theme)
        {
            XMLHttpRequestObj.setRequestHeader('X-Requested-Theme', document.body.dataset.theme);
            XMLHttpRequestObj.setRequestHeader('X-Requested-Layer', document.body.dataset.layer);
        }

        for(let index in this.additionalHeaders)
        {
            XMLHttpRequestObj.setRequestHeader(index, this.additionalHeaders[index]);
        }

        XMLHttpRequestObj.withCredentials = true;

        EventUtil.addEvent(XMLHttpRequestObj, 'readystatechange', () => {
            this.receiveData(XMLHttpRequestObj, options);
        });

        XMLHttpRequestObj.send(this.serialize(options.data));
    }


    sendForm (_form, {data = {}, callback = null, progress = null, url = null, method = 'POST'})
    {
        const options = {
            data, callback, progress, url, method
        };

        let formObj, i, ii, tmp;

        if(typeof _form === "object")
        {
            formObj = _form;
        }
        else
        {
            formObj = DomUtil.byId(_form);
        }

        const elements = formObj.getElementsByTagName("*");

        for(i = elements.length; i;)
        {
            --i;

            if( elements[i].name !== undefined &&
                elements[i].name !== "" &&
                elements[i].value !== undefined)
            {
                if(elements[i].type === "submit")
                {

                }
                else if(elements[i].type === "radio" || elements[i].type === "checkbox")
                {
                    if(elements[i].checked)
                    {
                        options.data[elements[i].name] = elements[i].dataset.value || elements[i].value;
                    }
                }
                else if(elements[i].type === "file")
                {
                    tmp = [];

                    for(ii = 0; ii < elements[i].files.length ; ++ii)
                    {
                        tmp.push(elements[i].files[ii].name);
                    }

                    options.data[elements[i].name] = tmp.join('/');
                }
                else
                {
                    options.data[elements[i].name] = elements[i].dataset.value || elements[i].value;
                }
            }
        }

        this.sendData(options);
    }

    sendFile(_file, {data = {}, callback = null, progress = null, url = null, method = 'POST'})
    {
        const options = {
            data, callback, progress, url, method
        };

        const XMLHttpRequestObj = new XMLHttpRequest();
        const fileObj = _file;

        let key;

        if(XMLHttpRequestObj && fileObj !== undefined)
        {
            if(fileObj.domObj)
            {
                cl.basic.addClassName(fileObj.domObj, 'processing');
            }

            const formData = new FormData();

            formData.append(data.fileField ? data.fileField : 'file', (fileObj.data ? fileObj.data : fileObj), fileObj.name);

            for(key in data)
            {
                formData.append(key, data[key]);
            }

            XMLHttpRequestObj.open(method, url || window.location.href, true);
            XMLHttpRequestObj.setRequestHeader('X-Requested-With', 'XMLHttpRequest');

            if(document.body.dataset.theme)
            {
                XMLHttpRequestObj.setRequestHeader('X-Requested-Theme', document.body.dataset.theme);
                XMLHttpRequestObj.setRequestHeader('X-Requested-Layer', document.body.dataset.layer);
            }

            XMLHttpRequestObj.withCredentials = true;

            EventUtil.addEvent(XMLHttpRequestObj, 'load', () => {
                this.receiveData(XMLHttpRequestObj, options);
            });

            EventUtil.addEvent(XMLHttpRequestObj, 'load', () => {
                cl.basic.removeClassName(fileObj.domObj, 'processing');
            });

            if(progress)
            {
                EventUtil.addEvent(XMLHttpRequestObj.upload, 'progress', (e) => {
                    progress(e);
                });
            }

            XMLHttpRequestObj.send(formData);
        }
    }

    receiveData(_XMLHttpRequestObj, _options)
    {
        if(_XMLHttpRequestObj.readyState === 4 && _XMLHttpRequestObj.status > 0)
        {
            let response;

            if(_XMLHttpRequestObj.status === 413)
            {
                cl.notification.show({
                    type: 'error',
                    message: 'Your file exceeds the allowed size.',
                    meta: {}
                }, true);

                if(typeof _options.callback === 'function')
                {
                    _options.callback({result: 'failed'});
                }

                (new Handler).processResponse({});

                return;
            }

            try
            {
                response = JSON.parse(_XMLHttpRequestObj.responseText);
            }
            catch(e)
            {
                cl.notification.show({
                    type: 'error',
                    message: 'There is a problem with the server response.' + e,
                    meta: {}
                }, true);

                cl.notification.show({
                    type: 'response',
                    message: "<pre class=\"debug-wrapper reponse\"><code class=\"debug\">" + _XMLHttpRequestObj.responseText + "</code></pre>",
                    meta: {}
                });

                this.handleResponse(_options, {});

                return;
            }

            this.handleResponse(_options, response);

            (new Handler).processResponse(response);

            if(this.activeXMLHttpRequest !== null)
            {
                this.activeXMLHttpRequest = null;
            }
        }
    }

    handleResponse(_options, response)
    {
        if(typeof _options.callback === 'function')
        {
            try
            {
                _options.callback(response);
            }
            catch(e)
            {
                console.log(e);
            }
        }
    }
}