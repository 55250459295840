
export default class Lightbox {

    scrollBarWidth = null;
    wrapperObj = null;
    contentObj = null;
    closeObj = null;

    display = false;
    url = null;
    closable = true;
    closableBackground = true;

    onClose = null;

    /**
     * @type {Dispatcher}
     */
    dispatcher = null;


    constructor() {
        this.scrollBarWidth = this.getScrollBarWidth();

        this.prepareDom();
        this.prepareEventListener();

        window.cl.LightBox = this.migrationFallback();
    }

    prepareDom()
    {
        var wrapper = cl.byId('lightbox');

        if(wrapper)
        {
            this.wrapperObj = wrapper;
            this.closeObj = cl.basic.getElementByClassName('close', wrapper);
            this.contentObj = cl.basic.getElementByClassName('lightbox-content', wrapper);
        }
        else
        {
            this.contentObj = cl.basic.createDomElement({
                tagName: 'div',
                className: 'lightbox-content'
            });

            this.closeObj = cl.basic.createDomElement({
                tagName: 'div',
                className: 'close',
                innerHTML: '<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>'
            });

            this.wrapperObj = cl.basic.createDomElement({
                tagName: 'div',
                className: 'lightbox-wrapper',
                children: [
                    {
                        tagName: 'div',
                        className: 'lightbox',
                        children: [
                            this.closeObj,
                            this.contentObj
                        ]
                    }
                ]
            });

            const pageWrapper = document.querySelector('.page-wrapper');

            if(!pageWrapper)
            {
                console.error('Missing dom element! There is no page wrapper (.page-wrapper) node in the current dom.');

                return ;
            }

            pageWrapper.before(this.wrapperObj);
        }

        document.head.appendChild(cl.basic.createDomElement({
            'tagName': 'style',
            'type': 'text/css',
            'innerHTML': 'body.show-lightbox{margin-right: ' + this.scrollBarWidth + 'px !important;}'
        }));
    }

    prepareEventListener()
    {
        const self = this;

        const close = function (e) {
            if(e.target.classList.contains('lightbox-wrapper') && !self.closableBackground) return;
            if(this !== e.target || !self.closable) return;

            self.close();
        };

        cl.event.addListener(this.closeObj, 'click', close);
        cl.event.addListener(this.wrapperObj, 'click', close);
        cl.event.addListener(document, 'keydown', (event) => {

            if(event.keyCode !== 27 || !this.closable) return;

            this.close();
        });

        cl.event.addListener(this.wrapperObj, 'scroll', function()
        {
            cl.loader.loadVisibleElements()
        }, {passive: true});

        const observer = new MutationObserver(() => {
            cl.loader.loadVisibleElements()
        });

        observer.observe(this.wrapperObj, {childList: true, subtree: true });
    }

    getScrollBarWidth()
    {
        const inner = cl.basic.createDomElement({'tagName': 'p'});

        inner.style.width = "100%";
        inner.style.height = "200px";

        const outer = cl.basic.createDomElement({'tagName': 'div'});
        outer.style.position = "absolute";
        outer.style.top = "0px";
        outer.style.left = "0px";
        outer.style.visibility = "hidden";
        outer.style.width = "200px";
        outer.style.height = "150px";
        outer.style.overflow = "hidden";
        outer.appendChild(inner);

        document.body.appendChild(outer);
        const w1 = inner.offsetWidth;
        outer.style.overflow = 'scroll';
        let w2 = inner.offsetWidth;
        if(w1 === w2) w2 = outer.clientWidth;

        document.body.removeChild(outer);

        return (w1 - w2);
    }

    close()
    {
        this.contentObj.innerHTML = '';

        if(this.onClose)
        {
            if(typeof this.onClose === "function")
            {
                this.onClose();
            }
            else
            {
                eval(this.onClose);
            }
        }

        document.body.classList.remove( 'show-lightbox');
        document.body.removeAttribute('data-lightbox');
        document.body.removeAttribute('data-lightbox-context');
        this.wrapperObj.removeAttribute('data-context');
        this.wrapperObj.removeAttribute('data-plugin');
    }

    show(_content)
    {
        if (!this.closable || _content === undefined) {
            return;
        }

        if (_content.option === undefined) {
            _content.option = {};
        }

        if (_content.option.closable !== undefined) {
            this.closable = _content.option.closable;
        }

        if (_content.option.url !== undefined) {
            this.url = _content.option.url;
        }

        if (_content.option.dataset !== undefined) {

            Object.keys(this.wrapperObj.dataset).forEach(dataKey => {
                delete this.wrapperObj.dataset[dataKey];
            });

            for(let key in _content.option.dataset)
            {
                this.wrapperObj.dataset[key] = _content.option.dataset[key];
            }
        }

        if (_content.option.onClose !== undefined) {
            this.onClose = _content.option.onClose;
        }

        if (_content.option.onShow !== undefined) {
            this.onShow = _content.option.onShow;
        }

        if (_content.option.closableBackground !== undefined) {
            this.closableBackground = _content.option.closableBackground;
        }

        for (let i = 0; i < this.wrapperObj.attributes.length; ++i)
        {
            if (this.wrapperObj.attributes[i].name.indexOf('data-') >= 0)
            {
                this.wrapperObj.removeAttribute(this.wrapperObj.attributes[i].name);
            }
        }

        if(this.closable)
        {
            cl.basic.removeClassName(this.wrapperObj, 'not-closable');
        }
        else
        {
            cl.basic.addClassName(this.wrapperObj, 'not-closable');
        }

        /* add request type as css-class */
        if (_content.request !== undefined || (_content.option.data !== undefined))
        {
            let tmp = {};

            if (_content.request !== undefined)
            {
                tmp = _content.request;
            }

            if (_content.option.data !== undefined)
            {
                tmp = _content.option.data;
            }

            for (let key in tmp)
            {
                this.wrapperObj.setAttribute('data-' + key, tmp[key]);
            }

            if(_content?.option?.data?.plugin)
            {
                document.body.dataset.lightboxContext = _content.option.data.plugin;
            }
        }

        if(this.setContent(_content))
        {
            cl.basic.addClassName(document.body, 'show-lightbox');
            document.body.dataset.lightbox = 'show';

            if(this.onShow)
            {
                if(typeof this.onShow === "function")
                {
                    this.onShow();
                }
                else
                {
                    eval(this.onShow);
                }
            }
        }
    }

    setContent(_content)
    {
        if (typeof _content === 'string')
        {
            _content = JSON.parse(_content);
        }

        if (_content instanceof Object)
        {
            if (_content.html !== undefined)
            {
                if (typeof _content.html === 'object')
                {
                    this.contentObj.appendChild(cl.basic.createDomElement(_content.html));
                }
                else
                {
                    this.contentObj.innerHTML = _content.html;
                }
            }
            else if (_content.request !== undefined)
            {
                cl.ajax.sendData({
                    url: this.url,
                    data: _content.request,
                    callback: (_data) => this.show(_data),
                });

                return false;
            }

            if (_content.execute !== undefined)
            {
                eval(_content.execute);
            }
        }
        else if (_content instanceof HTMLElement)
        {
            this.contentObj.innerHTML = '';

            this.contentObj.appendChild(_content);
        }

        setTimeout(() => {
            cl.loader.loadVisibleElements(this.wrapperObj);
        }, 250);

        return true;
    }

    /**
     * Migration information function
     *
     * @returns {{getInstance: (function(): {show: function(*): void})}}
     */
    migrationFallback() {

        return {
            getInstance: () => {

                console.warn('Migration information', {
                    from: 'cl.LightBox.getInstance().show(...)',
                    to: 'cl.lightbox.show(...)'
                });

                return {
                    show: (data) => cl.lightbox.show(data),
                    close: () => cl.lightbox.close(),
                }
            }
        }
    }
}