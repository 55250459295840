
class Util
{
    /**
     *
     * @param {Element|Element[]} _domElement
     * @param {string} _type
     * @param {function} _callback
     * @param _option
     */
    addEvent(_domElement, _type, _callback, _option = false)
    {
        if(Array.isArray(_domElement))
        {
            _domElement.map((domElement) => {
                this.addEvent(domElement, _type, _callback, _option);
            });
        }
        else
        {
            _domElement.addEventListener(_type, _callback, _option);
        }
    }

    addDelayedEvent(element, eventType, callback, delay = 250)
    {
        this.addEvent(element, eventType, function()
        {
            if(this.timer)
            {
                clearTimeout(this.timer);
            }

            this.timer = setTimeout(callback, delay);
        });
    }

    /**
     *
     * @param e
     */
    stopEvent(e)
    {
        if (e && e.preventDefault)
        {
            e.preventDefault();
            e.stopPropagation();
        }
    };
}

const EventUtil = new Util();

export default EventUtil;