export default class Cookie {
    set(name, value, days)
    {
        if(days)
        {
            const d = new Date();
            d.setTime(d.getTime() + (days*24*60*60*1000));
            const expires = "expires="+d.toUTCString();
            document.cookie = name + "=" + value + "; " + expires + "; path=/";

            return;
        }

        document.cookie = name + "=" + value;
    }

    get (name)
    {
        name += "=";
        const ca = document.cookie.split(';');
        let i, c;

        for(i=0; i < ca.length; ++i)
        {
            c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1);
            if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
        }

        return null;
    }

    reset(name)
    {
        this.set(name, '', -100);
    }
}