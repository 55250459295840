class NewEvent {
    createEvent(type) {
        let event;

        if(typeof(Event) === 'function')
        {
            event = new Event(type);
        }
        else
        {
            event = document.createEvent('Event');
            event.initEvent(type, true, true);
        }

        return event;
    }

    addListener (element, eventType, callback, options) {
        if(element === undefined) return false;

        if( typeof eventType === 'object' &&
            eventType[0] !== undefined)
        {
            let i;

            for(i = 0; i < eventType.length; ++i)
            {
                if(element["on" + eventType[i]] !== undefined)
                {
                    eventType = eventType[i];

                    break;
                }
            }
        }

        if(options === undefined)
        {
            options = false;
        }

        if(element.addEventListener)
        {
            element.addEventListener(eventType, callback, options);
        }
        else if(element.attachEvent)
        {
            const fixedCallback = function(e)
            {
                e = e || window.event;

                e.preventDefault = (function(e)
                {
                    return function()
                    {
                        e.returnValue = false;
                    }
                })(e);

                e.stopPropagation = (function(e)
                {
                    return function()
                    {
                        e.cancelBubble = true;
                    }
                })(e);

                e.target = e.srcElement;
                callback.call(element, e);
            };

            element.attachEvent('on' + eventType, fixedCallback);
        }

        return true;
    }

    addListeners(elements, eventType, callback) {
        let i = elements.length;

        while(i)
        {
            this.addListener(
                elements[--i],
                eventType,
                callback
            );
        }
    }

    removeListener(element, eventType, callback) {
        if(element.removeEventListener)
        {
            element.removeEventListener(eventType, callback, false);
        }
        else if(element.detachEvent)
        {
            element.detachEvent('on' + eventType, callback);
        }
    }

    removeListeners(elements, eventType, callback) {
        let i = elements.length;

        while(i)
        {
            this.removeListener(elements[--i], eventType, callback);
        }
    }

    dispatchEvent(element, eventType) {
        const event = typeof eventType === 'string' ? new Event(eventType) : eventType;

        if(element.fireEvent)
        {
            element.fireEvent('on' + eventType, event);
        }
        else
        {
            element.dispatchEvent(event);
        }
    }

    stopEvent(e) {
        if (e && e.preventDefault)
        {
            e.preventDefault();
            e.stopPropagation();
        }
        else if (window.event && window.event.returnValue)
        {
            window.event.returnValue = false;
        }
    }

    removeAllListeners(_obj) {
        const cloneObj = _obj.cloneNode(true);

        _obj.parentNode.replaceChild(cloneObj, _obj);

        return cloneObj;
    }

    convertTouchDownToMouseDown(_stopEvents) {
        return function(e)
        {
            cl.event.dispatchEvent(this, cl.event.convertTouchToMouse(e, 'mousedown', _stopEvents));
        }
    }

    convertTouchMoveToMouseMove(_stopEvents) {
        return function(e)
        {
            cl.event.dispatchEvent(this, cl.event.convertTouchToMouse(e, 'mousemove', _stopEvents));
        }
    }

    convertTouchToMouse (_event, _type, _stopEvents)
    {
        if(_stopEvents)
        {
            _event.stopPropagation();
            _event.preventDefault();
        }

        const mouse = document.createEvent('MouseEvent');
        mouse.initMouseEvent(_type, true, true, window, _event.detail,
            _event.touches[0].screenX, _event.touches[0].screenY,
            _event.touches[0].clientX, _event.touches[0].clientY,
            false, false, false, false,
            0, null);

        return mouse;
    }
}

const event = new NewEvent();

export default event;