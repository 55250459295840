import Loader from "./lib/Dom/Loader.es6";
import Updater from "./lib/Dom/Event/Updater.es6";
import Base from "./lib/Base.es6";
import Ajax from "./lib/Ajax.es6";
import Cookie from "./lib/Cookie.es6";
import Navigation from "./lib/Navigation.es6";
import Lightbox from "./lib/Lightbox.es6";
import Notification from "./lib/Notification.es6";
import event from "./lib/Event.es6";
import md5 from "./lib/MD5.es6";
import basic from "./lib/Basic.es6";

if(window.cl === undefined)
{
    window.cl = {};
}

window.cl.byId = ( id ) => { return document.getElementById( id ); };
window.cl.basic = basic;
window.cl.event = event;
window.cl.util = {
    md5: (string) => {
        return md5.md5(string);
    },
    basic,
    event
};
window.cl.cookie = new Cookie();
window.cl.notification = new Notification();
window.cl.loader = new Loader();
window.cl.ajax = new Ajax();
window.cl.navigation = new Navigation();
window.cl.lightbox = new Lightbox();
window.cl.updater = new Updater();
window.cl.base = new Base();

cl.registerClass = function(_class)
{
    _class.extension = [];

    _class.getExtension = function(_name)
    {
        if(_class.extension[_name] !== undefined)
        {
            return _class.extension[_name];
        }

        return false;
    };

    return _class;
};

cl.extension = new function()
{
    const extensions = [];

    this.load = function(_options)
    {
        if(_options.baseUrl === undefined)
        {
            console.error('{baseUrl: ...} is missing', _options);

            return false;
        }

        if(_options.extension === undefined)
        {
            console.error('{extension: ... } is missing');

            return false;
        }

        let extensionSrc = _options.baseUrl.split('/');

        extensionSrc.pop();
        extensionSrc.push('extension');
        extensionSrc.push(_options.extension + '.js');

        extensionSrc = extensionSrc.join('/');

        let appendScript = false;

        if (extensions[extensionSrc] === undefined)
        {
            extensions[extensionSrc] = {
                callbacks: [],
                loaded: false
            };

            appendScript = true;
        }

        if(extensions[extensionSrc].loaded)
        {
            _options.callback();

            return true;
        }
        else if(typeof _options.callback === 'function')
        {
            extensions[extensionSrc].callbacks.push(_options.callback);
        }

        if(appendScript)
        {
            const script = cl.basic.createDomElement({
                tagName: 'script',
                src: extensionSrc,
                type: 'application/javascript'
            });

            cl.event.addListener(script, 'load', function() {

                extensions[extensionSrc].loaded = true;

                const callbacks = extensions[extensionSrc].callbacks;
                let i;

                for(i = 0; i < callbacks.length; ++i)
                {
                    callbacks[i]();
                }
            });

            document.head.appendChild(script);
        }

        return true;
    }
};