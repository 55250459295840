
export default class Navigation
{
    constructor() {

        this.handle();
    }

    redirect(_uri, _target)
    {
        if(_target)
        {
            window.open(_uri, _target);

            return;
        }

        window.location.href = _uri;
    }

    reload(_options)
    {
        if(_options === undefined)
        {
            _options = [];
        }

        if(_options['holdPosition'])
        {
            window.location.hash = 'scrollTo-' + cl.basic.getYScroll();
        }

        if(_options['scrollToElement'])
        {
            window.location.hash = 'scrollToElement-' + _options['scrollToElement'];
        }

        if(_options['url'])
        {
            window.location.href = _options['url']
        }
        else
        {
            window.location.reload();
        }
    }

    handle()
    {
        if(window.location.hash.indexOf('#scrollToElement') > -1)
        {
            const hash = window.location.hash.toString();

            history.replaceState(null, null, ' ');

            const element = cl.byId(hash.replace('#scrollToElement-', ''));

            cl.basic.addClassName(element, 'set-new');

            cl.basic.scrollToElement(element);
        }
        else if(window.location.hash.indexOf('#scrollTo') > -1)
        {
            const hash = window.location.hash.toString(), _parent =  ((document.compatMode || "") === "CSS1Compat") ? document.documentElement : document.body;

            history.replaceState(null, null, ' ');

            _parent.scroll(0, parseInt(hash.replace('#scrollTo-', '')));
        }
    }
}