
import {EventUtil, DomUtil} from '../Dom/Util.es6'

export default class Loader {

    onLoadScript(obj, _options) {

        EventUtil.addEvent(obj, 'load', () => {
            this.initLoadingScripts(_options);
        });
    };

    initLoadingScripts({elements, callback}) {

        if(elements.length > 0)
        {
            const scriptObj = DomUtil.createDomElement({
                tagName: 'script',
                src: elements.shift()
            });

            this.onLoadScript(scriptObj, {
                elements, callback
            });

            document.body.appendChild(scriptObj);

            return;
        }

        if(callback)
        {
            try
            {
                callback();
            }
            catch (e)
            {
                console.error(e);
            }
        }
    };

    reloadScript(options)
    {
        this.initLoadingScripts(options);
    };
};