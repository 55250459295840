
import Ajax from "../../Ajax.es6";
import {DomUtil} from "../../Dom/Util.es6";

class Dispatcher {

    click(_element, _data) {

        switch(_data.type)
        {
            case 'lightbox':

                const prepareData = {};

                if(_data.url)
                {
                    prepareData.url = _data.url;

                    delete _data.url;
                }

                cl.lightbox.show({
                    option: prepareData,
                    request: _data
                });

                break;

            default:

                if(_data.data['toggle-like'])
                {
                    const domObj = DomUtil.byId(_data.data['toggle-like']);

                    if(domObj)
                    {
                        if(domObj.innerHTML !== '')
                        {
                            domObj.innerHTML = '';

                            return ;
                        }
                    }

                    delete _data.data['toggle-like'];
                }

                if(_data.data['confirm'])
                {
                    if(!confirm(_data.data['confirm']))
                    {
                        return ;
                    }

                    delete _data.data['confirm'];
                }

                for (let key in _data.data) {

                    if(typeof _data.data[key] !== 'string') continue;

                    if ((_data.data[key].startsWith('[') && _data.data[key].endsWith(']')) ||
                        (_data.data[key].startsWith('{') && _data.data[key].endsWith('}')))
                    {
                        _data.data[key] = JSON.parse(_data.data[key]);
                    }
                }

                (new Ajax()).sendData(_data);
        }
    }
}

const EventDispatcher = new Dispatcher();

export default EventDispatcher;