
class Css
{
    /**
     *
     * @param {Element|Element[]} _obj
     * @param {string|string[]} _className
     */
    addClassName(_obj = [], _className)
    {
        if(Array.isArray(_obj))
        {
            _obj.map((obj) => {
                this.addClassName(obj, _className);
            });
        }
        else if(Array.isArray(_className))
        {
            _className.map((className) => {
                this.addClassName(_obj, className);
            });
        }
        else
        {
            _obj.classList.add(_className);
        }
    }

    /**
     *
     * @param {Element|Element[]} _obj
     * @param {string|string[]} _className
     */
    removeClassName(_obj = [], _className)
    {
        if(Array.isArray(_obj))
        {
            _obj.map((obj) => {
                this.removeClassName(obj, _className);
            });
        }
        else if(Array.isArray(_className))
        {
            _className.map((className) => {
                this.removeClassName(_obj, className);
            });
        }
        else
        {
            _obj.classList.remove(_className);
        }
    }

    /**
     *
     * @param {Element|Element[]} _obj
     * @param {string} _sourceClassName
     * @param {string} _destinationClassName
     */
    changeClassName(_obj = [], _sourceClassName, _destinationClassName)
    {
        if(Array.isArray(_obj))
        {
            _obj.map((obj) => {
                this.changeClassName(obj, _sourceClassName, _destinationClassName);
            });
        }
        else if(_sourceClassName && _destinationClassName)
        {
            _obj.classList.replace(_sourceClassName, _destinationClassName);
        }
        else if(_obj.classList.contains(_sourceClassName))
        {
            _obj.classList.remove(_sourceClassName);
        }
        else
        {
            _obj.classList.add(_sourceClassName);
        }
    }

    /**
     *
     * @param {Element} _obj
     * @param {string} _className
     *
     * @returns {boolean}
     */
    hasClassName(_obj, _className)
    {
        return _obj.classList.contains(_className);
    }
}

const CssUtil = new Css();

export default CssUtil;